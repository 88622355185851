import { createSlice } from '@reduxjs/toolkit'

const layers = createSlice({
  name: 'layers',
  initialState: {
    ids: [],
    idsPoput: [],
    filters: {}
  },
  reducers: {
    addLayer: (draftState, {
      payload: {
        layerId
      }
    }) => {
      if (!draftState.ids.includes(layerId)) {
        draftState.ids.unshift(layerId)
        draftState.filters[layerId] = {}
        draftState[layerId] = { opacity: 100 }
      }
    },
    removeLayer: (draftState, {
      payload: {
        layerId
      }
    }) => {
      draftState.ids = [...draftState.ids.filter((id) => id !== layerId)]
    },
    addLayerPoput: (draftState, {
      payload: {
        layerId
      }
    }) => {
      if (!draftState.idsPoput.includes(layerId)) {
        draftState.idsPoput.unshift(layerId)
        draftState.filters[layerId] = {}
        draftState[layerId] = { opacity: 50 }
      }
    },
    removeLayerPoput: (draftState, {
      payload: {
        layerId
      }
    }) => {
      draftState.idsPoput = [...draftState.idsPoput.filter((id) => id !== layerId)]
    },
    orderLayerChange: (draftState, { payload: { startIndex, endIndex } }) => {
      const [removed] = draftState.ids.splice(startIndex, 1)
      draftState.ids.splice(endIndex, 0, removed)
    },
    addFilter: (draftState, {
      payload: {
        layerId,
        cql,
        type
      }
    }) => {
      draftState.filters[layerId][type] = cql
    },
    layerSliderChanged: (draftState, {
      payload: {
        layerId,
        opacity
      }
    }) => {
      draftState[layerId] = { opacity }
    }
  }
})

export default layers.reducer

const actions = { ...layers.actions }
export { actions }
