import React from 'react'

import PropTypes from 'prop-types'

import {
  SvgIcon, Tooltip, CardActionArea, Typography, Badge
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'state/ducks/categories'
import { actions as actionsTour } from 'state/ducks/tour'
import { getSubCategory } from 'utils/configQueries.js'

import useStyles from './styles'

const Icon = ({
  id, path, colorActive, categoriSelected, title
}) => {
  const useStyle = makeStyles(() => ({
    icon: {
      width: '60px',
      height: '60px',
      '&:hover': { color: colorActive },
      color: categoriSelected === id ? colorActive : 'white'
    },
    tooltipExpanded: {
      backgroundColor: '#db8b00',
      width: 'auto',
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      marginLeft: 22
    },
    text: {
      color: '#023b69'
    }
  }))
  const classes = useStyle()
  return (
    <Tooltip title={<Typography className={classes.text}>{title}</Typography>} placement="right" classes={{ tooltip: classes.tooltipExpanded }}>
      <SvgIcon className={classes.icon} component="div">
        { path }
      </SvgIcon>
    </Tooltip>
  )
}
/** Modifica las categorias mediante los cambios de estados. */
const Category = ({
  id, path, title, color
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const layersCount = useSelector((state) => state.layers.ids)
  const categoriSelected = useSelector((state) => state.categories?.sectionId[0])
  const subCategoriLayers = getSubCategory(title)
  let layersActives = 0
  subCategoriLayers.forEach(({ layers }) => {
    layers.forEach((e) => {
      if (layersCount.find((e2) => e2 === e.layerId)) {
        layersActives += 1
      }
    })
  })
  return (
    <CardActionArea
      onClick={() => {
        if (id === 'Tutorial') {
          dispatch(actionsTour.isVisibleTour(true))
        } else {
          dispatch(actions.categorySelected([title, color]))
        }
      }}
      className={classes.option}
      style={{ backgroundColor: categoriSelected === id ? 'white' : color }}
    >
      {/* <Badge
        badgeContent={layersActives}
        color="secondary"
      >
      </Badge> */}
      <Icon id={id} path={path} title={title} colorActive={color} categoriSelected={categoriSelected} />
    </CardActionArea>
  )
}

Category.propTypes = {
  /** Los parametros de la categoria que busca. */
  id: PropTypes.string.isRequired,
  path: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired

}

Icon.propTypes = {
  colorActive: PropTypes.string.isRequired,
  path: PropTypes.objectOf(PropTypes.any).isRequired
}

export default Category
