import React from 'react'
import Logo from 'img/icono.svg'
import {
  Avatar, Link
} from '@material-ui/core'

export default () => (
  <Link
    style={{
      display: 'flex',
      justifyContent: 'center'
    }}
    href="https://www.tresdefebrero.gov.ar/"
  >
    <Avatar
      src={Logo}
      style={{
        padding: '5px 5px', width: '60px', height: '60px', overflow: 'initial'
      }}
    />
  </Link>
)
