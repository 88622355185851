import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  IconButton, Divider, Box, Typography
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useSelector, useDispatch } from 'react-redux'

import { actions as actionsCategory } from 'state/ducks/categories'
import { actions as layersActions } from 'state/ducks/layers'
import { actions as mapActions } from 'state/ducks/map'
import { TourProvider } from '@reactour/tour'
import Routes from './routes'

import './theme/mapLibre.css'

export const ModalContext = React.createContext({ isModalOpen: true })

export default function App({ isAuthenticated }) {
  const dispatch = useDispatch()
  const isMapReady = useSelector((state) => state.map.isMapReady)
  const [step, setSteps] = useState(null)
  const layersP = ['Obras_y_proyectos_tipologia', 'Obras_y_proyectos_area']
  // localStorage.removeItem('data')

  useEffect(() => {
    const data = window.location.origin
    const link = localStorage.getItem('data')
    if (link === data) {
      dispatch(mapActions.openTutorial(false))
    } else {
      dispatch(mapActions.openTutorial(true))
      localStorage.setItem('data', data)
    }
  }, [isMapReady, dispatch])
  const firstView = JSON.parse(localStorage.getItem('isModalOpen')) || false

  if (!firstView && isMapReady) {
    // dispatch(actions.isVisibleTour(true))
    localStorage.setItem('isModalOpen', 'true')
  }

  const steps = [
    {
      padding: {
        mask: 0
      },
      content: () => (
        <Box>
          <Box style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: '#37BBED',
            fontSize: 35,
            fontWeight: 'bolder'
          }}
          >
            Bienvenido a GeoMop
          </Box>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            style={{
              margin: 10, backgroundColor: 'black', height: 1
            }}
          />
          <Box style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: 30,
            color: 'black',
            fontWeight: 'bolder'
          }}
          >
            Bienvenido al Visor del Geoportal del Ministerio de Obras Públicas de la nación. A través de esta herramienta podrá explorar diferentes conjuntos de datos relativos a la inversión realizada por el organismo en sus obras vigentes, proyectos a desarrollar y también datos de contexto de distintas categorías. Esperamos que disfrute esta herramienta y que le sea de utilidad!
          </Box>
        </Box>
      ),
      position: 'center'
    },
    {
      selector: '[data-tut="categories"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            1.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Menú</Typography>
            <Typography>
              En este menú podrá explorar las distintas categorías de información.
            </Typography>
          </Box>
        </Box>
      ),
      action: () => {
        dispatch(actionsCategory.categorySelected(['Información de Gestión e Inversión Pública', '#EF6C00']))
      },
      position: 'top'
    },
    {
      selector: '[data-tut="subcategory"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            2.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Subcategorías</Typography>
            <Typography>
              Acá están disponibles las subcategorías y sus respectivas capas.
            </Typography>
          </Box>
        </Box>
      ),
      action: () => {
        layersP.map((layerId) => dispatch(layersActions.addLayer({ layerId })))
      }
    },
    {
      selector: '[data-tut="buttonMove"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            3.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Orden de capas</Typography>
            <Typography>
              Utilizando este botón se pueden ordenar las diferentes capas
              encendidas, moviendo unas encima de otras.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '[data-tut="buttonDelete"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            4.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Cierre de capas</Typography>
            <Typography>
              Presionando en este botón es posible desactivar una capa que se encuentra activa.
            </Typography>
          </Box>
        </Box>
      ),
      position: 'top'
    },
    {
      selector: '[data-tut="buttonObras"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            5.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Búsqueda por obras o proyectos</Typography>
            <Typography>
              Seleccionando esta opción es posible realizar búsquedas de obras
              o proyectos mediante sus nombres.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '[data-tut="buttonLocalizaciones"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            6.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Búsqueda por localizaciones</Typography>
            <Typography>
              Seleccionando esta opción es posible realizar búsquedas de localizaciones.
            </Typography>
          </Box>
        </Box>
      ),
      position: 'top'
    },
    {
      selector: '[data-tut="buttonBaseLayer"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            7.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Capa base</Typography>
            <Typography>
              Este botón permite cambiar la capa base a utilizar en el visor.
            </Typography>
          </Box>
        </Box>
      ),
      position: 'top'
    },
    {
      selector: '[data-tut="buttonLine"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            8.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>
              Botón de medición
            </Typography>
            <Typography>
              Este botón permite medir distancias lineales sobre el mapa.
            </Typography>
          </Box>
        </Box>
      ),
      position: 'top'
    },
    {
      selector: '[data-tut="buttonTutorial"]',
      content: () => (
        <Box style={{ width: 190, display: 'flex', justifyContent: 'center' }}>
          <Box style={{
            color: '#37BBED',
            fontSize: 45,
            fontWeight: 'bold',
            paddingRight: 10
          }}
          >
            9.
          </Box>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Botón de tutorial</Typography>
            <Typography>
              Mediante este botón se puede activar el tutorial.
            </Typography>
          </Box>
        </Box>
      ),
      position: 'top'
    },
    {
      padding: {
        mask: 0
      },
      content: () => (
        <Box style={{
          width: 190, display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: 'large'
        }}
        >
          ¡A disfrutar el visor!
        </Box>
      ),
      position: 'center'
    }
  ]
  function ContentComponent(props) {
    const { content } = props.steps[props.currentStep]
    return (
      <Box style={{ padding: 10, background: 'white' }}>
        {step === null && (
        <Box style={{ padding: 10 }}>
          {typeof content === 'function'
            ? content({ ...props, someOtherStuff: 'Custom Text' })
            : content}
          <Box>
            {!props.last && (
            <Box style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
            >
              {props.currentStep === 0
            && (
            <Box style={{ paddingRight: 20 }}>
              ¿Cómo se utiliza?
            </Box>
            )}
              <Box>
                <IconButton
                  style={{
                    border: '3px solid #37BBED',
                    padding: 5
                  }}
                  onClick={() => {
                    if (props.currentStep === 10) {
                      props.setIsOpen(false)
                      setSteps(null)
                    } else {
                      setSteps(props.currentStep)
                      props.setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
                    }
                  }}
                >
                  <ArrowForwardIcon style={{ color: '#37BBED', fontSize: 25 }} />
                </IconButton>
              </Box>
            </Box>
            )}
          </Box>
        </Box>
        )}
        {step !== null && (
        <Box style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
        >
          {typeof content === 'function'
            ? content({ ...props, someOtherStuff: 'Custom Text' })
            : content}
          <Box>
            {!props.last && (
            <Box style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
            >
              {props.currentStep === 0
                    && (
                    <Box style={{ paddingRight: 20 }}>
                      ¿Cómo se utiliza?
                    </Box>
                    )}
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{
                  margin: 10, backgroundColor: 'black', height: 80
                }}
              />
              <Box>

                <IconButton
                  style={{
                    border: '3px solid #37BBED',
                    padding: 5
                  }}
                  onClick={() => {
                    if (props.currentStep === 10) {
                      props.setIsOpen(false)
                      setSteps(null)
                    } else {
                      setSteps(props.currentStep)
                      props.setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
                    }
                  }}
                >
                  <ArrowForwardIcon style={{ color: '#37BBED', fontSize: 25 }} />
                </IconButton>
              </Box>
            </Box>
            )}
          </Box>
        </Box>
        )}
        <>
      
        </>
        {step === null && (
        <>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            style={{
              margin: 10, backgroundColor: 'black', height: 1
            }}
          />
          <Box style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          }}
          >
            <IconButton
              onClick={() => {
                props.setIsOpen(false)
                props.setCurrentStep([0])
                setSteps(null)
              }}
              style={{
                marginTop: 10, padding: 8, fontSize: 14, borderRadius: 20, backgroundColor: '#0B72BB', color: '#ffffff'
              }}
            >
              Omitir intro
            </IconButton>
          </Box>
        </>
        )}
      </Box>
    )
  }

  return (
    <Box>
      <TourProvider
        ContentComponent={ContentComponent}
        styles={{
          popover: (base) => ({
            ...base,
            backgroundColor: '#ffffff',
            border: '4px solid #37bbed',
            borderRadius: 20,
            maxWidth: 'none',
            width: step === null ? '30%' : '20%',
            height: 'auto'
          }),
          navigation: (base) => ({
            ...base,
            marginTop: '0px !important'
          })
        }}
        prevButton={() => (
          <Box />
        )}
        showBadge={false}
        showDots={false}
        steps={steps}
        disableKeyboardNavigation={false, ['right', 'left']}
      >
        <BrowserRouter>
          <Routes authed={isAuthenticated} />
        </BrowserRouter>
      </TourProvider>
    </Box>
  )
}

App.propTypes = {
  isAuthenticated: PropTypes.bool
}
App.defaultProps = {
  isAuthenticated: false
}
