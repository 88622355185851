import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import { white } from 'material-ui/styles/colors'

const spacing = 8
const theme = createTheme({
  overrides: {
    spacing,
    MuiPagination: {
      ul: {
        justifyContent: 'center',
        color: 'black',
        fontSize: 15,
        paddingTop: 5
      }
    },
    MuiPaginationItem: {
      root: {
        height: 25,
        width: 25
      },
      icon: {
        color: 'black'
      }
    },
    MuiBadge: {
      colorSecondary: {
        backgroundColor: '#D7302C',
        fontWeight: 'bold',
        fontSize: 12
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 14,
          backgroundColor: white[500]
        }
      }
    },
    MuiSvgIcon: {
      root: {
        colorPrimary: '#fed304'
      }
    },
    MuiSlider: {
      root: {
        width: '95%'
      }
    },
    MuiAccordion: {
      root: {
        marginBottom: spacing,
        borderBottom: '1px solid #D9D9D9',
        boxShadow: 'none'
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 0,
        '&$expanded': {
          minHeight: 0
        }
      },
      content: {
        margin: '0px!important'
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: 0
      }
    },
    MuiFormLabel: {
      root: {
        color: '#707070'
      }
    },
    MuiListItemText: {
      secondary: {
        color: '#0B72BB'
      }
    },
    MuiDrawer: {
      paperAnchorDockedRight: {
        width: '303px',
        height: '88%',
        marginRight: 15,
        marginTop: 15,
        opacity: 0.9,
        '@media (max-width: 950px)': {
          width: '250px',
          opacity: 1.0,
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Nunito',
      'Open Sans'
    ].join(','),
    caption: {
      letterSpacing: 0,
      fontSize: '11.5px',
      lineHeight: '17px'
    },
    h5: {
      fontSize: '1.5rem'
    },
    body1: {
    }
  },
  palette: {
    text: {
      primary: '#707070',
      secondary: '#D9D9D9',
      Info: '#00f'
    },
    action: {
      active: '#22142b',
      hoverOpacity: 0.1
    },
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#0B72BB'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  }
})
export default theme
