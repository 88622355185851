import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { Modes, arrivalsDeparturesWithPredictive } from '@usig-gcba/recorridos-multimodo'

let actionsMutable = null

const getLegRouteIdFromOBA = ({ transportId, headsign }) => `${transportId}_${headsign}`
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const iteratorWithCancel = (iterator, onNext, isCancel, onError) => {
  if (isCancel instanceof Function && isCancel()) {
    iterator.return()
    return
  }
  iterator
    .next()
    .then(({ value, done }) => {
      if (!done) {
        iteratorWithCancel(iterator, onNext, isCancel, onError)
      }
      return { value, done }
    })
    .then(onNext)
    .catch((err) => onError && onError(err))
}

const startArrivalTimes = createAsyncThunk(
  'predictives/startArrivalTimes',
  async ({ stop, transportIds }, { getState, dispatch }) => {
    const arrivalsDeparturesIterator = () => arrivalsDeparturesWithPredictive({
      mode: Modes.BUS,
      stop,
      interval: 15000,
      filter: ({
        routeId: transportId,
        tripHeadsign: headsign
      }) => transportIds.includes(getLegRouteIdFromOBA({ transportId, headsign }))
    })
    const onNext = async ({
      value: arrivals
    }) => {
      if (arrivals) {
        dispatch(actionsMutable.cleanStop(stop))
        arrivals.forEach((arrival) => {
          const {
            routeId: transportId,
            tripHeadsign: headsign,
            arrivalTime
          } = arrival
          dispatch(
            actionsMutable.refreshStop({
              id: stop,
              transportId: getLegRouteIdFromOBA({ transportId, headsign }),
              arrivalTime
            })
          )
        })
      }
    }
    const isCancel = () => getState().router.from !== '90119e8403b09ff2405de7de02c67e99877f7f4a'
    const onError = async () => {
      await delay(3000)
      iteratorWithCancel(arrivalsDeparturesIterator(), onNext, isCancel, onError)
    }
    iteratorWithCancel(arrivalsDeparturesIterator(), onNext, isCancel, onError)
  }
)

const predictives = createSlice({
  name: 'predictives',
  initialState: {
    stops: {}
  },
  reducers: {
    cleanStop: (draftState, { payload: id }) => {
      draftState.stops[id] = undefined
    },
    refreshStop: (draftState, { payload: { id, transportId, arrivalTime } }) => {
      const stop = draftState.stops[id]
      draftState.stops[id] = stop || { [transportId]: [] }
      const { [transportId]: transport } = draftState.stops[id]
      draftState.stops[id][transportId] = transport || []
      if (transport && transport.length > 0 && transport[transport.length - 1] >= arrivalTime) {
        return
      }
      draftState.stops[id][transportId].push(arrivalTime)
      draftState.stops[id][transportId].sort((a, b) => a > b)
    }
  }
})

export default predictives.reducer

actionsMutable = {
  ...predictives.actions,
  startArrivalTimes
}
const actions = actionsMutable

export { actions }
