import { getApiUrl } from './configQueries'

const constantData = {
  infoLayers: {}
}

const getAttributes = async (idGeonode
) => {
  const result = await fetch(`https://geoportal.tresdefebrero.gob.ar/api/v2/layers/${idGeonode}?format=json`).then((response) => response.json()).then((data) => data.layer)
  return result.attribute_set.sort((a, b) => a.display_order - b.display_order)
}

const getFeatureInfo = async ({
  layer, x, y, height, width, bbox
}) => {
  const id = `${layer}_${x}_ ${y}`
  constantData.infoLayers[id] = constantData.infoLayers[id]
    || (await fetch(`${getApiUrl()}/ows?service=WMS&version=1.1.1&request=GetFeatureInfo&layers=${layer}&query_layers=${layer}&styles=&x=${x}&y=${y}&height=${height}&width=${width}&srs=EPSG:4326&bbox=${bbox}&info_format=application/json`).then((r) => r.json()))
  return constantData.infoLayers[id]
}

const getQuantile = ({ layerName, attribute, intervals }) => `${getApiUrl()}/rest/sldservice/${layerName}/classify.json?intervals=${intervals}&attribute=${attribute}`
const getFilterOptions = ({ layerName, attribute, max }) => ({
  url: `${getApiUrl()}/wps?service=WPS&outputFormat=json`,
  method: 'POST',
  mode: 'cors',
  credentials: 'omit',
  body: `<?xml version="1.0" encoding="UTF-8"?>
<wps:Execute xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
   <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">gs:PagedUnique</ows:Identifier>
   <wps:DataInputs>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">features</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">features</ows:Title>
         <wps:Data />
         <wps:Reference xmlns:xlink="http://www.w3.org/1999/xlink" mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
            <wps:Body>
               <wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.0.0">
                  <wfs:Query typeName="${layerName}">
                     <ogc:SortBy xmlns:ogc="http://www.opengis.net/ogc">
                        <ogc:SortProperty>
                           <ogc:PropertyName>${attribute}</ogc:PropertyName>
                        </ogc:SortProperty>
                     </ogc:SortBy>
                  </wfs:Query>
               </wfs:GetFeature>
            </wps:Body>
         </wps:Reference>
      </wps:Input>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">fieldName</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">fieldName</ows:Title>
         <wps:Data>
            <wps:LiteralData>${attribute}</wps:LiteralData>
         </wps:Data>
      </wps:Input>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">maxFeatures</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">maxFeatures</ows:Title>
         <wps:Data>
            <wps:LiteralData>${max}</wps:LiteralData>
         </wps:Data>
      </wps:Input>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">startIndex</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">startIndex</ows:Title>
         <wps:Data>
            <wps:LiteralData>0</wps:LiteralData>
         </wps:Data>
      </wps:Input>
   </wps:DataInputs>
   <wps:ResponseForm>
      <wps:RawDataOutput mimeType="application/json">
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">result</ows:Identifier>
      </wps:RawDataOutput>
   </wps:ResponseForm>
</wps:Execute>`
})
const getApiSector = () => 'https://geoportal.tresdefebrero.gob.ar/layers_manager/get_info/'

const getLayersAndSector = async () => {
  const result = await fetch(getApiSector())
  const { layers, sector } = await result.json()
  return { layers, sector }
}

const getParcel = ({ lng, lat }) => `${getApiUrl()}/catastro/parcela/?lng=${lng}&lat=${lat}`

const getParcelBySmp = (smp) => `${getApiUrl()}/catastro/parcela/?smp=${smp}`

const getGeometrical = (smp) => `${getApiUrl()}/catastro/geometria/?smp=${smp}`

const coordsToPlace = ({ lng, lat }) => `https://visor.obraspublicas.gob.ar/geoserver/normalizar/?lng=${lng}&lat=${lat}&minusculas=1`

const getCategoriesMaps = () => 'https://visor.obraspublicas.gob.ar/geoserver/mapainteractivoba/categorias/'

const getMaps = () => 'https://visor.obraspublicas.gob.ar/geoserver/mapainteractivoba/mapas/'

const getLayers = () => 'https://visor.obraspublicas.gob.ar/geoserver/mapainteractivoba/layers/'

const getTransp = ({ x, y }) => `https://visor.obraspublicas.gob.ar/geoserver/2.0/info_transporte/?x=${x}&y=${y}`

const getUtilsData = ({
  lng, lat, calle, altura
}) => `https://visor.obraspublicas.gob.ar/geoserver/datos_utiles?x=${lng}&y=${lat}&calle=${calle}&altura=${altura}`

const getImgTooltip = (layerName) => `${getApiUrl()}ows?service=WMS&request=GetLegendGraphic&format=image/png&LANGUAGE=spa&WIDTH=20&HEIGHT=20&LAYER=${layerName}&legend_options=fontAntiAliasing:true;fontSize:12;forceLabels:on;bgColor:0xFFFFFF;`
export {
  getAttributes,
  getFeatureInfo,
  getQuantile,
  getFilterOptions,
  getParcel,
  getParcelBySmp,
  getGeometrical,
  coordsToPlace,
  getCategoriesMaps,
  getMaps,
  getLayers,
  getLayersAndSector,
  getTransp,
  getUtilsData,
  getImgTooltip
}
