import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import useLayers from "utils/useLayers";
import Scrollbar from "react-smooth-scrollbar";
import MapaInteractivoGL from "utils/MapaInteractivoGL";
import { getFeatureInfo, getAttributes } from "utils/apiConfig";
import { getLayerName, getLayer } from "utils/configQueries";

const FeaturePopup = ({ features, attributes }) => {
  const info = features?.features?.map(({ properties }) =>
    Object.entries(properties)
  );
  const labels = [];
  const attributos = attributes?.result?.map(
    ({ attribute, attribute_label, visible }) => {
      if (visible) {
        if (attribute_label) return (labels[attribute] = attribute_label);
        else return (labels[attribute] = attribute);
      }
    }
  );
  const text = [];
  if (labels) {
    if(features.idfeatures==="geonode:Parcelario"){ 
    Object.entries(labels).map((e, index) => {
      const description = info[0].find((e2) => e2[0] === e[0]);

      if (
        "zona_des" !== e[0] &&
        "c2_des" !== e[0]&&
        "macroz_des" !== e[0] &&
        "fra_l" !== e[0] &&
        "manz_l" !== e[0] &&
        "parc_l" !== e[0] &&        
        "subzona"!== e[0] &&
        "macrozona"!== e[0] &&
        "fra"!== e[0] &&
        "manz"!== e[0] 
      ) {
        if(e[0]==="c2"|| e[0]==="c2_cpu") {
          if (description){
            if(description[1]===1){
              if(description[0]==="c2"){

                text[index] = ["", "C2"];
              }else{
                text[index] = ["", ""];
              }

            }
            else{
              if(description[1]=== 'true'){

                text[index] = ["", "C2"];
              }else{
                text[index] = ["", ""];
              }
            }
          } 
          else text[index] = ["", ""];
        }else
        
        if (description) text[index] = [labels[e[0]], description[1]];
        else text[index] = [labels[e[0]], ""];
      } else{
        if(
          "fra" === e[0] ||
          "fra_l"=== e[0] ||
          "manz"=== e[0] ||
          "manz_l"=== e[0] ||
          "c2_des"=== e[0]
        ){
          if( "manz"=== e[0] ||"fra" === e[0]){
            
            if (description){ 
              text[index] = [description[1]!==null?labels[e[0]]:"", description[1]!==null?description[1]:""];}

            }else {
            if (description) text[index] = ["", description[1]!==null?description[1]:""];
            else text[index] = ["", ""];
          }
          
        }else{
          if(  "subzona"=== e[0] ||
          "macrozona"=== e[0] ){
            if (description) {
              if(description[1]!==null&&description[1]!=='')
              text[index] = [labels[e[0]], description[1]];
              else text[index] = ["", ""];
            } 
          }else{

            if (description) text[index] = ["", description[1]];
            else text[index] = ["", ""];
          }
        }
      }
    });
  }else{
    Object.entries(labels).map((e, index) => {
      if(info[0].find((e2) => e2[0] === e[0])){
        const description = info[0].find((e2) => e2[0] === e[0]);
        if(description[1])
        text[index] = [labels[e[0]], description[1]];
      }
    })
  }
  }
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        width: "auto!important",
        paddingLeft: 10,
      }}
    >
      <Scrollbar style={{ height: "100%", width: "100%", padding: 0 }}>
        {text.map(([key, value]) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: 10,
              width: "98%",
            }}
          >
            <Typography
              style={{
                alignSelf: "flex-start",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {key?key+":":""}
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                paddingLeft: 4,
                textAlign: "end",
                width: 150,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Box>
        ))}
      </Scrollbar>
    </Box>
  );
};

const LayerInfoPanel = () => {
  const useStyle = makeStyles(() => ({
    drawer: {
      width: "30%",
      height: "80%",
    },
  }));
  const classes = useStyle();
  const [openDrawer, setOpenDrawer] = useState(false);

  const clickedPoint = useSelector((state) => state.map.clickedPoint);
  const coord = useSelector((state) => state.map.selectedCoords);
  const layersIds = useSelector((state) => state.layers?.ids);
  const { current } = useRef({
    clickedPoint: { x: 0, y: 0 },
    layersIds: [],
  });

  const { layers } = useLayers();

  const [pointFeatures, setPointFeatures] = useState([]);
  const [attributes, setAttributes] = useState([]);

  const map = MapaInteractivoGL()?.map;

  useEffect(() => {
    if (map && clickedPoint && coord) {
      const { x, y } = clickedPoint;

      const isCurrentCoords = () => x === current.x && y === current.y;
      const isCurrentLayersIds = () =>
        layersIds.length === current.layersIds.length;

      if (isCurrentCoords() && isCurrentLayersIds()) {
        return;
      }
      current.layersIds = [];
      layersIds.map((id) => {

        current.layersIds.push({id:id,idfeatures:getLayerName(id)});
      });
      current.x = x;
      current.y = y;
      setAttributes([]);
      setPointFeatures([]);

      if (layersIds.length === 0) {
        return;
      }

      const {
        offsetWidth: width,
        offsetHeight: height,
      } = document.getElementById("map");
      const bbox = map.getBounds().toArray().join(",");
      const featuresPromises = Promise.allSettled(
        current.layersIds.map(({idfeatures}) =>
          getFeatureInfo({
            layer: `${idfeatures}`,
            x: current.x,
            y: current.y,
            height,
            width,
            bbox,
          }).then(({ features }) => ({ idfeatures, features }))
        )
      );
      featuresPromises
        .then((data) =>
          data
            .filter(
              ({ status, value }) =>
                status === "fulfilled" && value?.features?.length
            )
            .map(({ value }) => value)
        )
        .then((data) => {
          if (isCurrentCoords() && isCurrentLayersIds()) {
            setPointFeatures(data);
            console.log(data,"data")
            const layersIds= data.map(({idfeatures}) =>{
              return current.layersIds.find((e)=>e.idfeatures===idfeatures)
            })
            const fechAttribute = Promise.allSettled(
              layersIds.map(({id}) =>
                getAttributes(getLayer(id).id_geonode).then((result) => ({
                  result,
                  id,
                }))
              )
            );
            fechAttribute
              .then((data) =>
                data
                  .filter(({ status, value }) => status === "fulfilled" && value)
                  .map(({ value }) => value)
              )
              .then((data) => {
                
                setAttributes(data);
              });
          }
        });
        
    }
  }, [clickedPoint, map, layersIds, current, coord]);

  useEffect(() => {
    if (pointFeatures.length) {
      setOpenDrawer(true);
    } else if (pointFeatures.length === 0) {
      setOpenDrawer(false);
    }
  }, [pointFeatures]);

  return (
    <Box>
      <Drawer variant="persistent" anchor="right" open={openDrawer}>
        <Scrollbar>
          <Box>
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              <IconButton
                onClick={() => {
                  setOpenDrawer(false);
                }}
              >
                <ClearIcon className={classes.iconClose} />
              </IconButton>
            </Box>
            {pointFeatures.map((e, i) => (
              <>
                <Typography
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    left: 0,
                    paddingTop: 10,
                    textAlign: "center",
                    fontSize: 13,
                    color: "#023b69",
                  }}
                >
                  {layers?.find(({ title }) => title === e?.idfeatures)?.name}
                </Typography>
                <FeaturePopup features={e} attributes={attributes[i]} />
                <Divider style={{ padding: 1, margin: 12 }} />
              </>
            ))}
          </Box>
        </Scrollbar>
      </Drawer>
    </Box>
  );
};
export default LayerInfoPanel;
