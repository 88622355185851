import React, { useEffect, useState } from 'react'
import { multiBuscador } from '@leandro.tevez/buscador'
import {
  Box,
  Avatar,
  SvgIcon,
  IconButton,
  InputBase,
  ListItemAvatar,
  ListItemText,
  ListItem,
  Paper
} from '@material-ui/core/'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PlaceIcon from '@material-ui/icons/Place'
import StarIcon from '@material-ui/icons/Star'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions as locationActions } from 'state/ducks/location'
import { actions as mapActions } from 'state/ducks/map'
import Scrollbar from 'react-smooth-scrollbar'
import icons from 'utils/svgIcons'
import useStyles from './styles'

function debounce(func, timeout) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

const handleDebounce = debounce(({ callback, ...args }) => {
  callback({ ...args })
}, 300)

const Seeker = () => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const [filterLocation, setFilterLocation] = useState('')
  const [buttonSelectedLocation, setButtonSelectedLocation] = useState('lugar')
  const dispatch = useDispatch()

  // TODO: implementar debounce
  // TODO: mover la busqueda a un useEffect.
  // TODO: garantizar que los resultados sugeridos, corresponden al texto que se visualiza (última busqueda).
  // TODO: Blur y busqueda deben ser consistentes. Si se desea que solo haya sugerencias cuando hay foco, hay que garantizarlo

  const handleMultiBuscador = ({ querys }) => {
    multiBuscador(querys).then((data) => data.map(({ title, result }) => (result
      ? result.map(({ properties, geometry }) => ({
        title: title === 'obras' ? properties.nombre_obr : properties.display_name,
        subTitle: title === 'obras' ? properties.id_obra : title,
        location: geometry,
        properties
      })) : [{
        data: {
          tipo: 'tipoalerta'
        },
        title: 'No se hallaron resultados coincidentes'
      }]))).then((sugerencias) => sugerencias)
      .then(setSuggestions)
  }

  const handleInputChange = ({ target: { value } }) => {
    // buscador({
    // url: 'https://visor.obraspublicas.gob.ar/gs/ows?service=WFS&outputFormat=json', filterProperty: 'nombre_obr', text, title: 'hola', layerName: 'geonode:obras_localizacion_puntos0'
    // })
    // .then(({ title, result }) => { console.log({ title, result }) })
    setInputValue(value)
    const obrasQuery = [{
      url: 'https://visor.obraspublicas.gob.ar/gs/ows?service=WFS&outputFormat=json',
      filterProperty: 'id_obra',
      text: value,
      title: 'obras',
      layerName: 'geonode:Obras_y_proyectos_puntuales_visorgeomop'
    },
    {
      url: 'https://visor.obraspublicas.gob.ar/gs/ows?service=WFS&outputFormat=json',
      filterProperty: 'nombre_obr',
      text: value,
      title: 'obras',
      layerName: 'geonode:Obras_y_proyectos_puntuales_visorgeomop'
    }]
    const lugarQuerys = {
      text: value, type: 'nominatim', title: 'lugar'
    }
    if (filterLocation === 'obras') {
      handleDebounce({
        callback: handleMultiBuscador,
        querys: [obrasQuery[0], obrasQuery[1]]
      })
    } else if (filterLocation === 'lugar') {
      handleDebounce({
        callback: handleMultiBuscador,
        querys: [lugarQuerys]
      })
    } else {
      handleDebounce({
        callback: handleMultiBuscador,
        querys: [{
          url: 'https://visor.obraspublicas.gob.ar/gs/ows?service=WFS&outputFormat=json',
          filterProperty: 'id_obra',
          text: value,
          title: 'obras',
          layerName: 'geonode:Obras_y_proyectos_puntuales_visorgeomop'
        },
        {
          url: 'https://visor.obraspublicas.gob.ar/gs/ows?service=WFS&outputFormat=json',
          filterProperty: 'nombre_obr',
          text: value,
          title: 'obras',
          layerName: 'geonode:Obras_y_proyectos_puntuales_visorgeomop'
        },
        {
          text: value, type: 'nominatim', title: 'lugar'
        }]
      })
    }
  }

  const handleSelectItem = () => {
    if (selectedSuggestion) {
      dispatch(locationActions.lugar(selectedSuggestion))
      dispatch(mapActions.clickOnMap({
        lng: selectedSuggestion?.location.coordinates[0],
        lat: selectedSuggestion?.location.coordinates[1]
      }))
      setInputValue('')
    }
  }

  const handleInputBlur = () => {
    setSuggestions([])
  }

  // TODO: cambiar por JSX
  const renderInput = (propsSelect) => {
    const {
      inputProps, classes: StyleClass, ...other
    } = propsSelect
    return (
      <InputBase
        className={StyleClass.input}
        inputProps={{
          ...inputProps
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    )
  }
  const renderSuggestion = (suggestionProps) => {
    const {
      suggestion, index, itemProps, highlightedIndex
    } = suggestionProps

    const title = suggestion.alias || suggestion.title || suggestion.nombre
    const subTitle = suggestion.subTitle
      ? suggestion.subTitle
      : suggestion.descripcion
    const Icono = suggestion.title ? PlaceIcon : StarIcon

    const isHighlighted = highlightedIndex === index
    if (isHighlighted) {
      setSelectedSuggestion(suggestion)
    }

    return (
      <>
        <ListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...itemProps}
          key={index}
          selected={isHighlighted}
          component="div"
          className={classes.list}
        >

          <ListItemAvatar>
            <Avatar>
              <Icono fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={title} secondary={subTitle} />
        </ListItem>
      </>
    )
  }

  // useEffect(() => {
  //   if (filterLocation) {
  //     const info = suggestions.flatMap((e) => e).filter((ele) => ele.subTitle === 'lugar')
  //     console.log('info :>> ', info)
  //   }
  // }, [suggestions, filterLocation])

  useEffect(() => {
    if (buttonSelectedLocation === 'lugar') {
      setFilterLocation('lugar')
    } else if (buttonSelectedLocation === 'obras') {
      setFilterLocation('obras')
    } else {
      setFilterLocation('')
    }
  }, [buttonSelectedLocation, suggestions])

  return (
    <Box>
      <Downshift
        id="usig-autocomplete"
        inputValue={inputValue}
        onSelect={handleSelectItem}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          selectedItem
        }) => {
          const {
            onChange, ...inputProps
          } = getInputProps({
            placeholder: 'Buscar'
          })

          return (
            <div data-tut="seeker">
              <Paper className={classes.root}>
                {renderInput({
                  classes,
                  inputProps,
                  onChange: handleInputChange,
                  onBlur: handleInputBlur
                })}
                <IconButton aria-label="search" className={classes.icon}>
                  <SvgIcon>
                    {icons.find((i) => i.id === 'lupa').path}
                  </SvgIcon>
                </IconButton>
              </Paper>

              <Box
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...getMenuProps()}
              >
                {suggestions.length !== 0 ? (
                  <Scrollbar className={classes.scrollbar}>
                    <Paper className={classes.paper} square>
                      {suggestions.flatMap((e) => e).map((suggestion, index) => renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.title }),
                        highlightedIndex,
                        selectedItem
                      }))}
                    </Paper>
                  </Scrollbar>
                ) : null}
              </Box>
            </div>
          )
        }}
      </Downshift>

    </Box>
  )
}

Seeker.propTypes = {
  onSelectItem: PropTypes.func.isRequired
}

export default Seeker
