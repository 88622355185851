import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import proj4 from 'proj4'

import {
  getParcel, getUtilsData, getTransp, coordsToPlace
} from 'utils/apiConfig'
import { actions as smpActions } from 'state/ducks/parcel'
import { actions as informationActions } from 'state/ducks/information'

proj4.defs([
  ['EPSG:221951', '+proj=tmerc +lat_0=-34.629269 +lon_0=-58.463300 +k=0.999998 +x_0=100000 +y_0=100000 +ellps=intl +units=m +no_defs'],
  ['EPSG:7433', '+proj=tmerc +lat_0=-34.629269 +lon_0=-58.463300 +k=0.999998 +x_0=100000 +y_0=100000 +ellps=intl +units=m +no_defs'],
  ['EPSG:97433', '+proj=tmerc +lat_0=-34.629269 +lon_0=-58.463300 +k=0.999998 +x_0=100000 +y_0=100085 +ellps=intl +units=m +no_defs']
])

const informationRequested = createAsyncThunk(
  'menuPlace/informationRequested',
  async (coord, { dispatch }) => {
    const { lng, lat } = coord
    const url = getParcel({ lng, lat })
    const parcelData = await fetch(url).then((data) => data.json())
    dispatch(smpActions.smpSelected(parcelData.smp))

    // Datos útiles
    const calle = parcelData.puertas[0]?.calle
    const altura = parcelData.puertas[0]?.altura
    const urlData = getUtilsData({
      lng, lat, calle, altura
    })
    const utilsData = await fetch(urlData).then((data) => data.json())

    const lngLat = `${lat.toFixed(6)}/${lng.toFixed(6)}`
    utilsData.latLng = lngLat

    // Transportes
    const proj = proj4('EPSG:4326', 'EPSG:7433', { x: coord.lng, y: coord.lat })
    const { x, y } = proj
    const urlTransp = getTransp({ x, y })
    const resp = await fetch(urlTransp)

    // La api devuelve el json entre paréntesis, con esto se eliminan
    const transpData = JSON.parse((await resp.text()).slice(1, -1))
    const { info: infoTransp } = transpData

    dispatch(informationActions.placeSelected({
      parcelData, utilsData, infoTransp
    }))
  },
  {
    condition: ({ lat, lng }) => lat !== undefined
      && lng !== undefined
  }
)

const directionRequest = createAsyncThunk(
  'menuPlace/directionRequest',
  async (coords) => {
    const url = coordsToPlace(coords)
    const response = await fetch(url)
    const { direccion } = await response.json()
    return direccion
  }
)

const menuPlace = createSlice({
  name: 'menuPlace',
  initialState: {
    placeFromSelected: null,
    placeToSelected: null,
    showMenu: false,
    direction: null
  },
  reducers: {
    placeToSelected: (draftState, action) => {
      draftState.placeToSelected = action.payload
    },
    placeFromSelected: (draftState, action) => {
      draftState.placeFromSelected = action.payload
    },
    showMenu: (draftState, action) => {
      draftState.showMenu = action.payload
    },
    toggleCoordsFromTo: (draftState) => {
      const auxFrom = draftState.placeFromSelected
      draftState.placeFromSelected = draftState.placeToSelected
      draftState.placeToSelected = auxFrom
    }
  },
  extraReducers: {
    [directionRequest.fulfilled]: (draftState, action) => {
      draftState.direction = action.payload
    }
  }
})

export default menuPlace.reducer

const actions = { ...menuPlace.actions, informationRequested, directionRequest }
export { actions }
