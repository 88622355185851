import React, { useState, useEffect } from 'react'

import {
  Box, Drawer, makeStyles, IconButton
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import Categories from 'components/Categories/Categories'
import { actions } from 'state/ducks/categories'
import Logo from 'components/Logo/Logo'
import { getCategories } from 'utils/configQueries'
import Scrollbar from 'react-smooth-scrollbar'
import Media from 'react-media'

const useStyles = makeStyles((theme) => ({
  sideBarPaper: {
    width: theme.spacing(9.75),
    background: '#023b69'
  },
  sideBarNone: {
    width: 26,
    background: '#023b69',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  logo: {
    marginTop: theme.spacing(1)
  },
  iconButtonOpen: {
    position: 'absolute',
    padding: 2,
    color: 'white'
  },
  iconButtonClose: {
    position: 'absolute',
    padding: 2,
    color: 'white',
    marginTop: 50,
    marginLeft: 25
  }
}))

const ConnectedPanel = () => {
  const categories = useSelector((state) => state.categories.sectionId)
  const isMapReady = useSelector((state) => state.map.isMapReady)
  const [sidebar, setSidebar] = useState(true)
  const showSidebar = () => { if (categories[0]) { setSidebar(!sidebar) } }
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (isMapReady) {
      setData(getCategories())
    }
  }, [isMapReady])

  const classes = useStyles()

  useEffect(() => {
    if (!sidebar) {
      dispatch(actions.setSectionOpen())
    }
  }, [sidebar])
  return (
    <Media queries={{ small: { maxWidth: 960 } }}>
      {(matches) => (matches.small
        ? (
          <Drawer
            variant="persistent"
            open
            PaperProps={{ elevation: 0, className: sidebar ? classes.sideBarPaper : classes.sideBarNone }}
          >
            {sidebar
            && (
            <>
              <Box className={classes.logo}>
                <Logo />
              </Box>
              <Categories data={data} />
            </>
            )}
          </Drawer>
        ) : (
          <Drawer
            variant="persistent"
            open
            PaperProps={{ elevation: 0, className: classes.sideBarPaper }}
          >
            <Box style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '98%'
            }}
            >
              <Scrollbar>
                <Box>
                  <Box className={classes.logo}>
                    <Logo />
                  </Box>
                  <Categories data={data} />

                </Box>
              </Scrollbar>
            </Box>
          </Drawer>
        ))}
    </Media>
  )
}

export default ConnectedPanel
