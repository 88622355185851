const getBaseConfig = (group, newGroup, l) => ({
  id: l,
  index: newGroup[l].orden,
  order: newGroup[l].order ? newGroup[l].order : 0,
  layerOptions: {
    id: `${group}_${l}`,
    title: newGroup[l].nombre,
    type: newGroup[l].format
  }
})

const tms = (group, newGroup, l) => {
  const config = getBaseConfig(group, newGroup, l)
  config.layerOptions.type = 'tms'
  config.layerOptions.options = {
    type: 'raster',
    maxzoom: parseInt(newGroup[l].options?.maxZoom ?? 18, 10),
    minzoom: parseInt(newGroup[l].options?.minZoom ?? 9, 10),
    source: {
      type: 'raster',
      scheme: 'tms',
      tiles: [newGroup[l].url],
      tileSize: parseInt(newGroup[l].options?.tileSize ?? 256, 10)
    },
    paint: {}
  }
  return config
}

const genericPoint = (group, newGroup, l) => {
  const config = getBaseConfig(group, newGroup, l)
  config.layerOptions.options = {
    source: {
      type: newGroup[l].format,
      data: newGroup[l].url,
      cluster: false
    },
    type: 'symbol',
    layout: {
      'icon-image': `${group}_${l}_${newGroup[l].nombre}`?.toLowerCase().replace(/\s+/g, '_'),
      'icon-size': 0.7
    }
  }
  return config
}

const genericLine = (group, newGroup, l) => {
  const config = getBaseConfig(group, newGroup, l)
  config.layerOptions.options = {
    source: {
      type: newGroup[l].format,
      data: newGroup[l].url,
      cluster: false
    },
    type: 'line',
    paint: {
      'line-color': newGroup[l].style?.color ?? '#e76056',
      'line-width': parseFloat(newGroup[l].style?.width ?? '4')
    }
  }
  return config
}

/* eslint-disable camelcase */
const estaciones_de_servicio = (group, newGroup, l) => {
  const config = getBaseConfig(group, newGroup, l)
  config.layerOptions.options = {
    source: {
      type: newGroup[l].format,
      data: newGroup[l].url,
      cluster: false
    },
    layers: ['YPF', 'SHELL', 'ESSO', 'PETROBRAS'].map((brand) => ({
      id: `${l}_${brand}`,
      type: 'symbol',
      layout: {
        'icon-image': brand,
        'icon-size': 0.7
      },
      filter: ['==', ['get', 'Nombre'], brand]
    }))
  }
  return config
}

/* eslint-disable camelcase */
const cortes_de_transito = (group, newGroup, l) => {
  const config = getBaseConfig(group, newGroup, l)
  config.layerOptions.options = {
    source: {
      type: newGroup[l].format,
      data: newGroup[l].url,
      cluster: false
    },
    layers: ['YPF', 'SHELL', 'ESSO', 'PETROBRAS'].map((brand) => ({
      id: `${l}_${brand}`,
      type: 'symbol',
      layout: {
        'icon-image': 'YPF',
        'icon-size': 0.7
      },
      filter: ['==', ['get', 'Nombre'], brand]
    }))
  }
  return config
}

export default {
  tms, genericPoint, genericLine, estaciones_de_servicio, cortes_de_transito
}
