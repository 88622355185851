import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getCategoriesMaps, getMaps, getLayers } from 'utils/apiConfig'

import { actions as mapActions } from 'state/ducks/map'
import builders from './builders'

const getConfigLayer = (group, newGroup, l) => {
  const builder = builders[newGroup[l].builder ?? l]
  if (!builder) {
    /* eslint-disable no-console */
    console.error(`getConfigLayer builder ${l} does not exist`)
  }
  const config = builder && builder(group, newGroup, l)
  return config
}

const getThematicsData = createAsyncThunk(
  'thematicsMaps/getThematicsMaps',
  async (_, { dispatch }) => {
    const urlCat = getCategoriesMaps()
    const respCat = await fetch(urlCat)
    const categories = await respCat.json()

    const urlMaps = getMaps()
    const respMap = await fetch(urlMaps)
    const maps = await respMap.json()

    const urlLayers = getLayers()
    const respLayers = await fetch(urlLayers)
    const layers = await respLayers.json()
      .then(({ hospitales, ...otras }) => ({
        hospitales: {
          hospitales_generales_de_agudos: {
            orden: 1,
            nombre: 'Hospitales Generales de Agudos',
            url: '//geoexpress-dev.kan.com.ar/geoserver/geonode/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=geonode%3Acalles4&maxFeatures=50&outputFormat=application%2Fjson&ENV=&access_token=MvnAXXcIQzw30hqbnqPKtUeOPKjbtR',
            format: 'geojson',
            builder: 'genericLine',
            icon: {
              iconUrl: 'https://visor.obraspublicas.gob.ar/geoserver//symbols/b/hospital_general_de_agudos.png',
              iconSize: [
                24,
                24
              ],
              iconAnchor: [
                12,
                12
              ],
              shadowUrl: 'https://visor.obraspublicas.gob.ar/geoserver/media/repok/uploads/mapainteractivoba/7.png',
              shadowSize: [
                24,
                24
              ],
              shadowAnchor: [
                12,
                12
              ]
            }
          }
        },
        ...otras
      }))

    const newLayersGroup = []
    Object.keys(layers).forEach((group) => {
      const newGroup = layers[group]
      const layerGroup = {
        id: group,
        title: group,
        layers: Object.keys(newGroup)
          .map((l) => getConfigLayer(group, newGroup, l))
          .filter((l) => l)
      }
      newLayersGroup.push(layerGroup)
    })
    dispatch(mapActions.addGroup(newLayersGroup))
    return { categories, maps, layers }
  }
)

const thematicsMaps = createSlice({
  name: 'thematicsMaps',
  initialState: {
    categories: {},
    maps: {},
    layers: {}
  },
  reducers: {},
  extraReducers: {
    [getThematicsData.fulfilled]: (draftState, { payload: { categories, maps, layers } }) => {
      draftState.categories = categories
      draftState.maps = maps
      draftState.layers = layers
    },
    [getThematicsData.rejected]: (draftState) => {
      draftState.categories = {}
      draftState.maps = {}
      draftState.layers = {}
    }
  }
})

export default thematicsMaps.reducer

const actions = { ...thematicsMaps.actions, getThematicsData }
export { actions }
