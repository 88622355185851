import React, { useEffect, useState } from 'react'

import { Drawer } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'

import ThematicMaps from 'components/Sections/SubSection/ThematicMaps'

import { actions as layersActions } from 'state/ducks/layers'
import { getLayersAndSector } from 'utils/apiConfig'
import { getSubCategory } from 'utils/configQueries'
import { useSection } from './useSection'

const Section = () => {
  const dispatch = useDispatch()
  const isShow = useSelector((state) => state.categories.sectionOpen)
  const sectionId = useSelector((state) => state.categories.sectionId)
  const lastIndex = sectionId.length - 1
  const sectionSelected = sectionId[lastIndex]
  const { title, options } = useSection(sectionSelected)
  const layerIds = useSelector((state) => state.layers.ids)
  const handlerChangeSelected = (layerId, idLayerPoput, isSelected) => {
    const action = isSelected
      ? layersActions.addLayer
      : layersActions.removeLayer
    dispatch(action({ layerId }))

    const actionPoput = isSelected
      ? layersActions.addLayerPoput
      : layersActions.removeLayerPoput
    dispatch(actionPoput({ layerId: idLayerPoput }))
  }
  return (
    <Drawer variant="persistent" open={isShow}>
      {isShow && (
        <ThematicMaps
          title={title}
          options={options}
          selected={layerIds}
          onChangeSelected={handlerChangeSelected}
        />
      )}
    </Drawer>
  )
}

export default Section
