import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, ButtonBase, Card } from '@material-ui/core'
import { Public as World } from '@material-ui/icons'

const BaseLayersControl = ({
  layers,
  width,
  height,
  gap,
  onLayerSelected
}) => {
  // const [layerId, setLayer] =
  const [isOpen, setIsOpen] = useState(false)
  const refMenu = useRef(null)
  const controlGroup = document.querySelectorAll(
    '.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group'
  )[3]
  useEffect(() => {
    if (controlGroup) {
      controlGroup.insertBefore(refMenu.current, controlGroup.firstChild)
    }
  }, [refMenu, controlGroup])

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  const distance = width + gap
  return (
    <>
      <ButtonBase ref={refMenu} style={{ backgroundColor: '#DB8B00', margin: '10px 10px 0px 0px' }}>
        <World onClick={handleOpen} style={{ color: '#023b69' }} />
        {isOpen && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: layers.length * -1 * distance,
              display: 'flex',
              direction: 'row'
            }}
          >
            {layers?.map(({ id, imageUrl }, idx) => (
              <Card
                key={id}
                style={{
                  position: 'absolute',
                  top: 0,
                  width,
                  height,
                  left: idx * distance
                }}
                onClick={() => onLayerSelected(id)}
              >
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    width,
                    height,
                    border: 'solid 2px lightblue',
                    background: imageUrl,
                    backgroundSize: 'cover'
                  }}
                />
              </Card>
            ))}
          </Box>
        )}
      </ButtonBase>
    </>
  )
}
BaseLayersControl.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  gap: PropTypes.number.isRequired,
  onLayerSelected: PropTypes.func.isRequired
}
export default BaseLayersControl
