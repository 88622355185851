import React from 'react'

import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import Category from './Category'
import useStyles from './styles'
/**
Identifica las diferentes categorias. */
const Categories = ({ data }) => {
  const classes = useStyles()

  return (
    <Box data-tut="categories">
      <Box className={classes.options}>
        {

          data.map(({
            id, title, path, color
          }) => (
            <Category
              key={id}
              id={id}
              path={path}
              title={title}
              color={color}
            />
          ))
          }
      </Box>
    </Box>
  )
}

Categories.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Categories
