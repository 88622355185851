import { createSlice } from '@reduxjs/toolkit'

const categories = createSlice({
  name: 'categories',
  initialState: {
    sectionOpen: false,
    sectionId: [''],
    color:''
  },
  reducers: {
    categorySelected: (draftState, { payload }) => {
      
      if (payload[0] === draftState.sectionId[0]) {
        draftState.sectionOpen = false
        draftState.sectionId = ['']
        draftState.color=payload[1]
      } else {
        draftState.sectionOpen = true
        draftState.sectionId = ['']
        draftState.sectionId[0] = payload[0]
        draftState.color=payload[1]
      }
    },
    sectionSelected: (draftState, { payload }) => {
      draftState.sectionId.push(payload[0])
    },
    sectionBack: (draftState) => {
      draftState.sectionId.pop()
    },
    setSectionOpen: (draftState) => {
      draftState.sectionOpen = false
      draftState.sectionId = ['']
      draftState.color=''
    }
  }
})

export default categories.reducer

const actions = { ...categories.actions }
export { actions }
