import { getSubCategory } from 'utils/configQueries'

export const useSection = (id) => {
  const subcategories= getSubCategory(id) ?? {}
 const title= subcategories[0]?.category
  const options =  subcategories.map(({layers}) => {

    return layers?.map(({
    id, title, subTitle, link, urlDownload, description , layerId, name, range_filter,selection_filter,range_text,selection_text
  }) => ({
    id,
    subTitle: title,
    title: subTitle,
    url: urlDownload,
    link,
    description , 
    layerId, 
    name, 
    range_filter,
    selection_filter,
    range_text,
    selection_text
  })) 
}).flatMap(e=>e)
  return {
    title,
    options
  }
}
