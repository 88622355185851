import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import hash from 'object-hash'

import Recorridos, { getGeoJson } from '@usig-gcba/recorridos-multimodo'
import { coordsToPlace } from 'utils/apiConfig'
import getColor from 'utils/randomColors'

import { actions as entitiesActions } from 'state/ducks/entities'
import { actions as actionsPredictives } from 'state/ducks/predictives'
import { actions as categoriesActions } from 'state/ducks/categories'
import { actions as routerActions } from 'state/ducks/router'
// import { getLayersGroups } from 'utils/configQueries'

const recorridos = Recorridos()

const initialStateRecorridos = {
  maxWalkDistance: 500,
  mode: {
    walk: false,
    car: false,
    bike: false,
    bus: true,
    rail: true,
    transit: false,
    subway: true
  },
  isChangingInit: false
}

const optionsChanged = createAsyncThunk(
  'router/optionsChanged',
  async ({
    walk,
    car,
    bike,
    bus,
    rail,
    transit,
    subway,
    maxWalkDistance
  }, { getState }) => {
    const {
      mode: currentMode,
      maxWalkDistance: currentMaxWalkDistance
    } = getState().router.options

    const nexState = {
      mode: {
        walk: walk === undefined ? currentMode.walk : walk,
        car: car === undefined ? currentMode.car : car,
        bike: bike === undefined ? currentMode.bike : bike,
        bus: bus === undefined ? currentMode.bus : bus,
        rail: rail === undefined ? currentMode.rail : rail,
        transit: transit === undefined ? currentMode.transit : transit,
        subway: subway === undefined ? currentMode.subway : subway
      },
      maxWalkDistance: maxWalkDistance === undefined ? currentMaxWalkDistance : maxWalkDistance
    }

    recorridos.setConfig({
      mode: Object.keys(nexState.mode).map(
        (m) => (nexState.mode[m] ? m.toUpperCase() : null)
      ).filter(Boolean),
      maxWalkDistance: maxWalkDistance === undefined ? currentMaxWalkDistance : maxWalkDistance
    })

    return nexState
  },
  {
    condition: (_, { getState }) => !getState().router.isChangingInit
  }
)

const recorridosSliceConfig = {
  initialState: initialStateRecorridos,
  extraReducers: {
    [optionsChanged.pending]: (draftState) => {
      draftState.options.isChangingInit = true
    },
    [optionsChanged.fulfilled]: (draftState, {
      payload: {
        mode: {
          walk,
          car,
          bike,
          bus,
          rail,
          transit,
          subway
        },
        maxWalkDistance
      }

    }) => {
      draftState.options.isChangingInit = false
      draftState.options.mode.walk = walk
      draftState.options.mode.car = car
      draftState.options.mode.bike = bike
      draftState.options.mode.bus = bus
      draftState.options.mode.rail = rail
      draftState.options.mode.transit = transit
      draftState.options.mode.subway = subway
      draftState.options.maxWalkDistance = maxWalkDistance
    },
    [optionsChanged.rejected]: (draftState) => {
      draftState.options.isChangingInit = false
    }
  }
}

const selectedRoute = createAsyncThunk(
  'router/selectedRoute',
  async (id, { getState }) => {
    const selected = getState().entities.routes.byId[id]
    const selectedAux = JSON.parse(JSON.stringify(selected))
    const geoJsonRoute = getGeoJson(selectedAux)
    const { color } = selected
    return { ...geoJsonRoute, id, color }
  },
  {
    condition: (_, { getState }) => getState().router.from && getState().router.to
  }
)

const getLegRouteIdFromOTP = ({ agencyId, transportId, headsign }) => `${agencyId}_${transportId.substr(2)}_${headsign}`

const getStopsGrouped = (routes) => {
  const firstStopsGrouped = routes
    .reduce((stops,
      {
        firstStop: {
          mode,
          stopCode,
          transportId
        }
      }) => {
      const stopIdx = stops
        .findIndex(({
          stopCode: code,
          mode: stopMode
        }) => code === stopCode && mode === stopMode)
      if (stopIdx < 0) {
        stops.push({
          stopCode,
          mode,
          transportIds: [transportId]
        })
      } else if (!stops[stopIdx].transportIds.includes(transportId)) {
        stops[stopIdx].transportIds.push(transportId)
      }
      return stops
    }, [])
  return firstStopsGrouped
}

const cleanRoutes = (geoJsonRoute, dispatch) => {
  geoJsonRoute.map(({ id }) => (
    dispatch(routerActions.unselectedRoute(id))
  ))
}

const routesRequested = createAsyncThunk(
  'router/routesRequested',
  async (_, { getState, dispatch }) => {
    const {
      router: { from, to, geoJsonRoute },
      entities: {
        places: { byId }
      },
      entities: { searches }
    } = getState()

    cleanRoutes(geoJsonRoute, dispatch)
    const { sectionId, sectionOpen } = getState().categories
    if (!sectionId || sectionId[0] !== 'Router' || !sectionOpen) {
      dispatch(categoriesActions.categorySelected('Router'))
    }
    dispatch(entitiesActions.cleanExpiredRoutes())
    const searchId = `${from}_${to}`
    // Es una busqueda en memoria
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!searches.byId[searchId]) {
      // TODO: Obtener los recorridos de una busqueda reciente
    }
    const { coords: fromCoords } = byId[from]
    const { coords: toCoords } = byId[to]
    const dataFrom = {
      lat: fromCoords.lat,
      lng: fromCoords.lng
    }
    const dataTo = {
      lat: toCoords.lat,
      lng: toCoords.lng
    }
    const color = getColor()
    await recorridos
      .getRoutes(dataFrom, dataTo, `${from}_${to}`)
      .then(({ requestId, plan }) => {
        const routes = plan?.itineraries.map(({
          id, duration, legs
        }) => {
          const {
            mode, agencyId = '', routeId: transportId = '', headsign, from: { stopCode } = {}
          } = legs.find(({ mode: legMode }) => legMode !== 'WALK') || {}
          return {
            id,
            tiempo: Math.trunc(duration / 60),
            descripcionResumen: 'Un resumen',
            legs,
            color: color.next().value,
            firstStop: {
              mode,
              stopCode,
              transportId: getLegRouteIdFromOTP({ agencyId, transportId, headsign })
            }
          }
        })
        dispatch(
          entitiesActions.routesObtained(routes)
        )
        dispatch(
          entitiesActions.searchObtained({
            id: requestId,
            routes: plan?.itineraries.map(({ id }) => id)
          })
        )

        getStopsGrouped(routes).forEach((stop) => {
          const { mode, stopCode, transportIds } = stop
          dispatch(
            actionsPredictives
              .startArrivalTimes({
                mode,
                stop: stopCode,
                transportIds
              })
          )
        })
      })
      .catch(() => {
        dispatch(
          entitiesActions.routesError()
        )
      })
  },
  {
    condition: (_, { getState }) => getState().router.from && getState().router.to
  }
)

const fromCoordsSelected = createAsyncThunk(
  'router/fromCoordsSelected',
  async (coords, { dispatch }) => {
    // eslint-disable-next-line no-use-before-define
    const place = await setPlace(coords, dispatch, 'from')
    dispatch(entitiesActions.placeSelected(place))
    dispatch(routesRequested())
    return place.nombre
  }
)

const toCoordsSelected = createAsyncThunk(
  'router/toCoordsSelected',
  async (coords, { dispatch }) => {
    // eslint-disable-next-line no-use-before-define
    const place = await setPlace(coords, dispatch, 'to')
    dispatch(entitiesActions.placeSelected(place))
    dispatch(routesRequested())
    return place.nombre
  }
)

const showUntil = (draftState) => {
  draftState.showUntil = true
  draftState.showSuggestion = false
}

const favoriteSelected = createAsyncThunk(
  'router/favoriteSelected',
  async (coords, { dispatch }) => {
    // eslint-disable-next-line no-use-before-define
    const place = await setPlace(coords, dispatch)
    dispatch(entitiesActions.addFavorite(place))
  }
)

const favRouteSelected = createAsyncThunk(
  'router/favRouteSelected',
  async ({
    id, from, to, favRoute
  }, { dispatch }) => {
    dispatch(categoriesActions.categorySelected('Router'))
    return {
      id, from, to, favRoute
    }
  }
)

const router = createSlice({
  name: 'router',
  initialState: {
    isEditing: false,
    showUntil: true,
    showSuggestion: false,
    from: null,
    to: null,
    nameTo: null,
    nameFrom: null,
    favRoute: null,
    isSearching: false,
    options: { ...recorridosSliceConfig.initialState },
    geoJsonRoute: [],
    unselectedRouteId: null,
    isLoading: false,
    selectedRoutes: {}
  },

  reducers: {
    fromSelected: (draftState, { payload: { nombre, coords } }) => {
      draftState.from = hash({ nombre, coords })
    },
    toSelected: (draftState, { payload: { nombre, coords } }) => {
      draftState.to = hash({ nombre, coords })
    },
    showSearches: (draftState) => {
      draftState.isEditing = false
      showUntil(draftState)
    },
    showPlaces: (draftState) => {
      draftState.isEditing = true
      showUntil(draftState)
    },
    inputChange: (draftState) => {
      draftState.showSuggestion = true
    },
    hideUntil: (draftState) => {
      draftState.showUntil = false
    },
    unselectedRoute: (draftState, action) => {
      draftState.unselectedRouteId = action.payload
    },
    toggleInputFromTo: (draftState) => {
      const auxFrom = draftState.nameFrom
      draftState.nameFrom = draftState.nameTo
      draftState.nameTo = auxFrom
      const auxTo = draftState.to
      draftState.from = draftState.to
      draftState.to = auxTo
    },
    accordionChange: (draftState, { payload: { id, isExpanded } }) => {
      draftState.selectedRoutes[id] = isExpanded
    }
  },
  extraReducers: {
    [routesRequested.pending]: (draftState) => {
      draftState.isSearching = true
    },
    [routesRequested.fulfilled]: (draftState) => {
      draftState.isSearching = false
      draftState.favRoute = null
    },
    [routesRequested.rejected]: (draftState) => {
      draftState.isSearching = false
    },
    [selectedRoute.pending]: (draftState) => {
      draftState.isLoading = true
    },
    [selectedRoute.fulfilled]: (draftState, action) => {
      draftState.isLoading = false
      draftState.unselectedRouteId = null
      draftState.geoJsonRoute.push(action.payload)
    },
    [selectedRoute.rejected]: (draftState) => {
      draftState.isLoading = false
    },
    [toCoordsSelected.rejected]: (draftState) => {
      draftState.nameTo = null
    },
    [toCoordsSelected.fulfilled]: (draftState, action) => {
      draftState.nameTo = action.payload
    },
    [fromCoordsSelected.rejected]: (draftState) => {
      draftState.nameFrom = null
    },
    [fromCoordsSelected.fulfilled]: (draftState, action) => {
      draftState.nameFrom = action.payload
    },
    [favRouteSelected.fulfilled]: (draftState, {
      payload: { from, to, favRoute }
    }) => {
      draftState.nameFrom = from
      draftState.nameTo = to
      draftState.favRoute = favRoute
    },
    ...recorridosSliceConfig.extraReducers
  }
})

export default router.reducer

const actions = {
  ...router.actions,
  routesRequested,
  selectedRoute,
  fromCoordsSelected,
  toCoordsSelected,
  optionsChanged,
  favoriteSelected,
  favRouteSelected
}

// Se necesita definir la función aquí porque utiliza actions
// Se deshabilita eslint error en las llamadas a la función
async function setPlace(coords, dispatch, fromTo) {
  // coords = {lat: ..., lng: ...}
  const url = coordsToPlace(coords)
  const response = await fetch(url)
  const { nombre_calle: calle, direccion, nombre_calle_cruce: cruce } = await response.json()
  const place = {
    id: direccion,
    nombre: `${calle} y ${cruce}`,
    direccion,
    coords
  }
  if (fromTo) {
    dispatch(actions[`${fromTo}Selected`](place))
  }
  return place
}

export { actions }
