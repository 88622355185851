import React, { useEffect, useRef } from 'react'
import MapaInteractivoGL from 'utils/MapaInteractivoGL'

const Layer = ({
  sourceId, sourceConfig, cql = '', layerConfig, opacity
}) => {
  const { map } = MapaInteractivoGL()
  const cqlRef = useRef()

  useEffect(() => {
    cqlRef.current = cql

    return () => {
      if (layerConfig?.id && map.getLayer(layerConfig.id)) {
        map.removeLayer(layerConfig.id)
        map.removeSource(sourceId)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (cqlRef.current !== cql && sourceId && map.getSource(sourceId)) {
      map.removeLayer(layerConfig.id)
      map.removeSource(sourceId)
    }

    if (sourceId && !map.getSource(sourceId)) {
      const sourceConfigAux = { ...sourceConfig, tiles: [...sourceConfig.tiles] }
      const cqlFilter = []
      if (cql?.options?.length) {
        cqlFilter.push(cql.options);
      }
      if (cql?.range?.length) {
        cqlFilter.push(cql.range);
      }
      if (cqlFilter.length) {
        sourceConfigAux.tiles[0] += `&CQL_FILTER=(${cqlFilter.join('AND')})`
      }
      map.addSource(sourceId, sourceConfigAux)
    }
    if (layerConfig?.id) {
      if (!map.getLayer(layerConfig.id)) {
        map.addLayer(layerConfig)
      }
      map.setLayoutProperty(layerConfig.id, 'visibility', 'visible')
    }
    map.setPaintProperty(layerConfig.id, 'raster-opacity', opacity / 100)
  }, [map, sourceId, sourceConfig, cql, layerConfig, opacity])
 
  return <input id={layerConfig?.id ?? 'without_id'} type="hidden" />
}

export default Layer
