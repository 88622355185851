import randomColor from 'randomcolor'

// Se hizo de ésta forma para mantener un orden en las gamas de colores
// En caso que se opte por utilizar una lista u otro método basta con cambiar ésta función
export default function* getColor() {
  while (true) {
    yield randomColor({ luminosity: 'dark', hue: 'blue' })
    yield randomColor({ luminosity: 'dark', hue: 'red' })
    yield randomColor({ luminosity: 'dark', hue: 'green' })
    yield randomColor({ luminosity: 'dark', hue: 'purple' })
    yield randomColor({ luminosity: 'dark', hue: 'orange' })
    yield randomColor({ luminosity: 'dark', hue: 'yellow' })
  }
}
