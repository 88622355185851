import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions as measureActions } from 'state/ducks/measure'
import { IconButton, Box } from '@material-ui/core'
import Timeline from '@material-ui/icons/Timeline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Lines from 'components/Lines'
import { useTour } from '@reactour/tour'
import { actions as mapActions } from 'state/ducks/map'
import MapaInteractivoGL from 'utils/MapaInteractivoGL'
import icons from 'utils/svgIcons'
import useStyles from './styles'

const Measure = () => {
  const [isActive, setIsActive] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const coord = useSelector((state) => state?.map?.selectedCoords)
  const isMapReady = useSelector((state) => state.map.isMapReady)
  const {
    setIsOpen, isOpen, currentStep, setCurrentStep
  } = useTour()

  const dispatch = useDispatch()
  useEffect(() => {
    if (isActive && coord) {
      const { lng, lat } = coord
      if (!coordinates.find(([ln, la]) => ln === lng && la === lat)) {
        setCoordinates([...coordinates, [lng, lat]])
      }
    }
  }, [coord, coordinates, isActive])
  useEffect(() => {
    if (isMapReady) {
      const { map } = MapaInteractivoGL()
      dispatch(mapActions.removeSelectedCoords())
      setCoordinates([])
      if (isActive) {
        map.getCanvas().style.cursor = 'crosshair'
      } else {
        map.getCanvas().style.cursor = ''
      }
    }
  }, [isActive, isMapReady])
  const openTutorial = () => {
    setIsOpen(true)
  }
  const handleMeasure = () => {
    setIsActive(!isActive)
    dispatch(mapActions.removeSelectedCoords())
    dispatch(measureActions.lineButton(!isActive))
  }

  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box id="buttonLine" className={classes.boxButtonLine}>
        {isActive && (
          <Lines points={coordinates} />
        )}
        <IconButton
          data-tut="buttonLine"
          onClick={handleMeasure}
          className={classes.iconButton}
        >
          {isActive ? icons.find((i) => i.id === 'measure').path : icons.find((i) => i.id === 'measureBlue').path}
        </IconButton>
      </Box>
    </Box>
  )
}

export default Measure

