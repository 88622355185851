import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  boxButtonLine: {
    backgroundColor: '#db8b00!important',
    width: 30,
    height: 30,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10
  }
}))
